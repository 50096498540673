import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("opponent")
  ? JSON.parse(localStorage.getItem("opponent"))
  : {
      opponent: {},
      opponentScore: 0,
      opponentCorrectAnswer: 0,
      opponentWrongAnswer: 0,
    };

const opponentSlice = createSlice({
  name: "opponent",
  initialState,
  reducers: {
    setOpponentImage: (state, action) => {
      state.opponent.image = action.payload;
      localStorage.setItem("opponent", JSON.stringify(state));
      return state;
    },
    setOpponentName: (state, action) => {
      state.opponent.name = action.payload;
      localStorage.setItem("opponent", JSON.stringify(state));
      return state;
    },
    beginnerModeOpponentScore: (state, action) => {
      const random = Math.floor(Math.random() * (2 - 0)) + 0;
      console.log(random);
      if (random === 1) {
        state.opponentScore = state.opponentScore + action.payload;
        state.opponentCorrectAnswer = state.opponentCorrectAnswer + 1;
        localStorage.setItem("opponent", JSON.stringify(state));
      
      } else {
        // state.opponentScore = state.opponentScore - action.payload;
        state.opponentWrongAnswer = state.opponentWrongAnswer + 1;
        localStorage.setItem("opponent", JSON.stringify(state));
      }
      return state;
    },
    resetOpponentScore: (state, action) => {
      state.opponentScore = 0;
      state.opponentCorrectAnswer = 0;
      state.opponentWrongAnswer = 0;
      localStorage.setItem("opponent", JSON.stringify(state));
      return state;
    },
  },
});
export const {
  setOpponentImage,
  setOpponentName,
  resetOpponentScore,
  beginnerModeOpponentScore,
} = opponentSlice.actions;
export default opponentSlice;
