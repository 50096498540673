import React from "react";
import { useRouteError } from "react-router-dom";
import classes from "./ErrorBoundary.module.css";
import LayoutNew from "./LayoutNew";
import SearchBox from "./SearchBox";
import HeaderNew from "./HeaderNew";
import MainLayout from "./MainLayout";
import TabsNew from "./TabsNew";

const ErrorBoundary = () => {
  const error = useRouteError();
  return (
    <LayoutNew>
      <SearchBox />
      <HeaderNew />
      <MainLayout>
      <TabsNew value={null} />
        <div className={classes.message_container}>
          <p className={classes.text}>Some Error Occured!</p>
          <p className={classes.text}>{error?.message || "Unknown Error"}</p>
        </div>
      </MainLayout>
    </LayoutNew>
  );
};

export default ErrorBoundary;
