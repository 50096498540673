import React from "react";
import { Box, Modal, Button, Typography, Backdrop, Fade } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideLoginModalBox } from "../../slices/loginModalSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import classes from "./ModalForLoginToPlay.module.css";

const ModalForLoginToPlay = () => {
  const navigate = useNavigate();
  const { loginModalBox } = useSelector((state) => state.loginModal);

  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0 solid #8c52ff",
    boxShadow: 24,
  };
  const handleClose = () => {
    dispatch(hideLoginModalBox());
  };

  const handleNavigate = (route) => {
    navigate(route);
    handleClose();
  };
  return (
    <Modal
      open={loginModalBox}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={loginModalBox}>
        <Box sx={style}>
          <div className={classes.modal_navbar}>
            <div className={classes.image_container}>
              <img
                className={classes.image}
                src="/images/logo.png"
                alt="Quiz Box"
              />
            </div>
            <div className={classes.close_icon}>
              <CloseIcon
                onClick={handleClose}
                sx={{ cursor: "pointer", color: "white", fontSize: "2rem" }}
              />
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.text}>
              <h2>You have to Login in order to play the game</h2>
            </div>
            <div className={classes.buttons_container}>
              <div>
                <button onClick={handleClose} className={classes.close_button} type="button">Close</button>
              </div>
              <div>
                <button   onClick={() => handleNavigate("/login")} className={classes.play_button} type="button">Login</button>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalForLoginToPlay;
