import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HomeIcon from "@mui/icons-material/Home";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classes from "./TabsNew.module.css";

const TabsNew = (props) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const handleNavigate = (route) => {
    navigate(route);
  };
  const checkTokenAndNavigate = () => {
    if (!user) {
      navigate("/login");
    } else {
      navigate("/account");
    }
  };
  return (
    <div className={classes.tabs_container}>
      <Tabs
        value={props.value}
        indicatorColor="error"
      >
        <Tab
        className={`${classes.tab}`}
          icon={<HomeIcon sx={{fontSize:"2rem"}} />}
          onClick={() => handleNavigate("/")}
          label="Home"
          selected={props.value===0}
          sx={{
            '&.Mui-selected': {
              color: 'white', 
              backgroundColor:"black"
            },
          }}
        />
        <Tab
        className={classes.tab}

          label="All Quizes"
          onClick={() => handleNavigate("/all-quizes")}
          icon={<GridViewIcon sx={{fontSize:"2rem"}}/>}
          selected={props.value===1}
          sx={{
            '&.Mui-selected': {
              color: 'white', 
              backgroundColor:"black"
            },
          }}
        />
        <Tab
        className={classes.tab}

          label="Leader Board"
          onClick={() => handleNavigate("/leaderboard")}
          icon={<LeaderboardIcon sx={{fontSize:"2rem"}} />}
          selected={props.value===2}
          sx={{
            '&.Mui-selected': {
              color: 'white', 
              backgroundColor:"black"
            },
          }}
        />

        <Tab
        className={classes.tab}

          onClick={() => {
            checkTokenAndNavigate();
          }}
          label={props.label ? props.label : user ? "Account" : "Login"}
          icon={<PersonIcon sx={{fontSize:"2rem"}} />}
          selected={props.value===3}
          sx={{
            '&.Mui-selected': {
              color: 'white', 
              backgroundColor:"black"
            },
          }}
        />
      </Tabs>
    </div>
  );
};

export default TabsNew;
