import {
  Avatar,
  Box,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideSearchBox } from "../../slices/searchSlice";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { baseURL, search } from "../../api";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import classes from "./SearchBox.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "0 solid #8c52ff",
  boxShadow: 24,
  p: 4,
};

const SearchBox = () => {
  const { searchBox } = useSelector((state) => state.search);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideSearchBox());
  };
  const handleSearch = async (e) => {
    // dispatch(hideSearchBox());
    e.preventDefault();
    // console.log(searchText);
    const data = { searchText };
    try {
      setLoading(true);
      const res = await axios.post(`${baseURL}${search}`, data);
      console.log(res);
      setSearchResults(res.data.result);
      setLoading(false);
    } catch (err) {
      // console.log(err);
      toast.error(
        err?.response?.data?.message ||
          err?.data?.result?.message ||
          err?.message
      );
      dispatch(hideSearchBox());
      setLoading(false);
    }
  };
  return (
    <Modal
      open={searchBox}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modal}>
        <form onSubmit={handleSearch}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            {/* <TextField
              id="search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              label="Search Quizes"
              variant="standard"
              sx={{fontSize:"3rem"}}
            /> */}

            <div className={classes.input_group}>
              <input
                id="search"
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search"
              />
            </div>

            <Box
              sx={{
                marginTop: "1%",
              }}
            >
              <IconButton aria-label="search" type="submit">
                <SearchIcon
                  color="primary"
                  sx={{ fontSize: "3rem", cursor: "pointer" }}
                />
              </IconButton>
            </Box>
          </Box>
        </form>
        {loading && <Loader />}
        {searchResults.length > 0 && (
          <Box sx={{ overflowY: "scroll", height: "400px" }}>
            <List>
              {searchResults.map((dataItem) => {
                return (
                  <React.Fragment key={dataItem.id}>
                    <ListItem
                      // key={dataItem.id}
                      secondaryAction={
                        <Link
                          to={`/game/details/${dataItem.categoryName}/${dataItem.categoryId}`}
                          onClick={() => handleClose()}
                        >
                          <IconButton edge="end" aria-label="link">
                            <ArrowForwardIosIcon sx={{ fontSize: "2rem" }} />
                          </IconButton>
                        </Link>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={dataItem.categoryImage}
                          variant="square"
                          alt={dataItem.categoryName}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", fontSize: "2rem" }}
                            className={classes.game_title}
                          >
                            {dataItem.categoryName}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "1.5rem" }}
                            className={classes.game_description}
                          >
                            {dataItem.description}
                          </Typography>
                        }
                      />
                    </ListItem>

                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default SearchBox;
