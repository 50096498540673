import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("answers")?JSON.parse(localStorage.getItem("answers")):
{
    correctAnswer:0,
    correctQuestions:[],
    wrongQuestions:[],
    answerChoosed:[],
    wrongAnswer:0
};

const AnswersSlice=createSlice({
    name:"answers",
    initialState,
    reducers:{
        increaseCorrectAnswer:(state,action)=>{
            state.correctAnswer=state.correctAnswer+1;
            state.correctQuestions=[...state.correctQuestions,action.payload];
            localStorage.setItem("answers",JSON.stringify(state));
            return state;
        },
        increaseWrongAnswer:(state,action)=>{
            state.wrongAnswer=state.wrongAnswer+1;
            state.wrongQuestions=[...state.wrongQuestions,action.payload.question];
            state.answerChoosed=[...state.answerChoosed,action.payload.yourAnswer];
            localStorage.setItem("answers",JSON.stringify(state));
            return state;
        },
       resetCorrectAnswer:(state,action)=>{
        state.correctAnswer=0;
        state.correctQuestions=[];
        localStorage.setItem("answers",JSON.stringify(state));
        return state;
       },
       resetWrongAnswer:(state,action)=>{
        state.wrongAnswer=0;
        state.wrongQuestions=[];
        state.answerChoosed=[];
        localStorage.setItem("answers",JSON.stringify(state));
        return state;
       },
    }
}) 

export const {increaseCorrectAnswer,increaseWrongAnswer,resetCorrectAnswer,resetWrongAnswer}=AnswersSlice.actions;
export default AnswersSlice;