import React from "react";
import classes from "./HeaderNew.module.css";
import { useDispatch } from "react-redux";
import { showSearchBox } from "../../slices/searchSlice";
import { useNavigate } from "react-router-dom";

const HeaderNew = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(showSearchBox());
  };
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div className={classes.header}>
    <div className={classes.headerLogo}>
      <img src="/images/logo.png" className={classes.logo} 
      onClick={handleNavigate}
      />
    </div>
    <div className={classes.search_icon} onClick={handleClick}>
    <i className={`fa-solid fa-magnifying-glass ${classes.icon}`}></i>
    </div>
  </div>
  );
};

export default HeaderNew;
