import React, { useEffect, useState } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import classes from "./GameDetails2.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showLoginModalBox } from "../../slices/loginModalSlice";
import { showDifficultyModalBox } from "../../slices/difficultyLevelsSlice";
import axios from "axios";
import { baseURL, getGameDescription } from "../../api";
import NavigationTitle from "../../components/newComponents/NavigationTitle";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import StarRateIcon from "@mui/icons-material/StarRate";
import ModalForLoginToPlay from "../../components/newComponents/ModalForLoginToPlay";
import ModalForDifficultyLevels from "../../components/newComponents/ModalForDifficultyLevels";
import SearchBox from "../../components/newComponents/SearchBox";
import LoadingNew from "../../components/newComponents/LoadingNew";

const GameDetails2 = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const checkTokenAndNavigate = () => {
    if (!user) {
      dispatch(showLoginModalBox());
    }
    else if (user?.response) {
    dispatch(showDifficultyModalBox());
    }
    else {
      dispatch(showLoginModalBox());
    }
  };

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.post(
          `${baseURL}${getGameDescription}?categoryId=${categoryId}`
        );
        setData(res.data.result[0]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchDataFromBackend();
  }, [categoryId]);
  return (
    <LayoutNew>
      <HeaderNew />
      <SearchBox />
      <MainLayout>
        {loading ? (
          <LoadingNew />
        ) : (
          <>
            <NavigationTitle title={data?.categoryName} />
            <div className={classes.quiz_grid}>
              <div className={classes.quiz_image_container}>
                <img
                  className={classes.image}
                  src={data?.categoryImage}
                  alt={data?.categoryName}
                />
              </div>
              <div className={classes.quiz_details_container}>
                <div className={classes.quiz_question_container}>
                  <div className={classes.icon_question}>
                    <QuestionMarkIcon
                      sx={{ color: "white", fontSize: "2rem" }}
                    />
                  </div>
                  <div className={classes.quiz_text}>
                    <h2>{data?.question} Questions</h2>
                  </div>
                </div>
                <div className={classes.quiz_point_container}>
                  <div className={classes.icon_star}>
                    <StarRateIcon sx={{ color: "white", fontSize: "2rem" }} />
                  </div>
                  <div className={classes.quiz_text}>
                    <h2>{data?.points} Points</h2>
                  </div>
                </div>
              </div>
              <div className={classes.quiz_description}>
                <h2 className={classes.quiz_description_text}>
                  {data?.description}
                </h2>
              </div>

              <div className={classes.play_button_container}>
                <button
                  onClick={() => checkTokenAndNavigate()}
                  type="button"
                  className={classes.button}
                >
                  Play Quiz
                </button>
              </div>
            </div>
            <ModalForLoginToPlay />
            <ModalForDifficultyLevels
              category={data?.categoryName}
              categoryId={data?.categoryId}
            />
          </>
        )}
      </MainLayout>
    </LayoutNew>
  );
};

export default GameDetails2;
