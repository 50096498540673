import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  difficultyModalBox: false,
};

const DifficultyModalSlice = createSlice({
  name: "difficultyModal",
  initialState,
  reducers: {
    showDifficultyModalBox: (state, action) => {
      state.difficultyModalBox = true;
      return state;
    },
    hideDifficultyModalBox: (state, action) => {
      state.difficultyModalBox = false;
      return state;
    },
  },
});

export const { showDifficultyModalBox, hideDifficultyModalBox } =
  DifficultyModalSlice.actions;
export default DifficultyModalSlice;
