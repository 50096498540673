import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    toggleMenu: (state, action) => {
      // Toggle the value of 'open'
      state.open = !state.open;
      return state;
    },
  },
});

export const { toggleMenu } = menuSlice.actions;
export default menuSlice;
