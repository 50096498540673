import React, { useEffect, useState } from "react";
import classes from "./Home2.module.css";
import HeaderNew from "../../components/newComponents/HeaderNew";
import TabsNew from "../../components/newComponents/TabsNew";
import { useSelector } from "react-redux";
import { baseURL, quizURL } from "../../api";
import axios from "axios";
import { toast } from "react-toastify";
import PopularQuizNew from "../../components/newComponents/PopularQuizNew";
import FeaturedQuizNew from "../../components/newComponents/FeaturedQuizNew";
import LayoutNew from "../../components/newComponents/LayoutNew";
import MainLayout from "../../components/newComponents/MainLayout";
import SearchBox from "../../components/newComponents/SearchBox";
import LoadingNew from "../../components/newComponents/LoadingNew";

const Home2 = () => {
  const [quizes, setQuizes] = useState([]);
  const [popularQuizes,setPopularQuizes]=useState([]);
  const [loading, setLoading] = useState(true);
  const { open } = useSelector((state) => state.menu);

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${baseURL}${quizURL}`);
        setQuizes(res.data.result);
        setPopularQuizes(()=>[...res?.data?.result].sort(() => Math.random() - 0.5).slice(0,6));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(err?.response?.data?.message || err?.message);
      }
    };
    fetchDataFromBackend();
  }, []);
  return (
    <LayoutNew>
      <SearchBox />
      <HeaderNew />
      <MainLayout>
        <TabsNew value={0} />
        {loading ? (
          <LoadingNew />
        ) : (
          <>
            <PopularQuizNew popularQuizes={popularQuizes} />
            <FeaturedQuizNew quizes={quizes} />
          </>
        )}
      </MainLayout>
    </LayoutNew>
  );
};

export default Home2;
