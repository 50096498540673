import React from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import SearchBox from "../../components/newComponents/SearchBox";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import TabsNew from "../../components/newComponents/TabsNew";
import classes from "./PageNotFound.module.css";

const PageNotFound = () => {
  return (
    <LayoutNew>
      <SearchBox />
      <HeaderNew />
      <MainLayout>
        <TabsNew value={null} />
        <div className={classes.message_container}>
          <p className={classes.text}>Page Not Found!</p>
        </div>
      </MainLayout>
    </LayoutNew>
  );
};

export default PageNotFound;
