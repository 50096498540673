import React from 'react'
import classes from "./LoadingNew.module.css";
import Lottie from 'lottie-react';
import loadingAnimation from "../../staticAnimations/loadingAnimation.json";

const LoadingNew = () => {
  return (
    <div className={classes.loadingContainer}>
        <div className={classes.animationContainer}>
            <Lottie animationData={loadingAnimation} loop={true} autoplay={true} className={classes.animation} />
        </div>
    </div>
  )
}

export default LoadingNew;