import { createSlice } from "@reduxjs/toolkit";

const initialState={
    searchBox:false
}

const SearchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    showSearchBox: (state, action) => {
        state.searchBox=true;
        return state;
    },
    hideSearchBox: (state, action) => {
        state.searchBox=false;
        return state;
    },
  },
});

export const { showSearchBox, hideSearchBox } = SearchSlice.actions;
export default SearchSlice;
