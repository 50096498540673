import React, { useEffect, useState } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import NavigationTitle from "../../components/newComponents/NavigationTitle";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL, historyURL } from "../../api";
import { toast } from "react-toastify";
import classes from "./History3.module.css";
import SearchBox from "../../components/newComponents/SearchBox";
import LoadingNew from "../../components/newComponents/LoadingNew";

const History3 = () => {
  const { user } = useSelector((state) => state.user);
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState();
  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${baseURL}${historyURL}`, {
          headers: {
            Authorization: `Bearer ${user?.response}`,
          },
        });
        // console.log(res);
        setHistories(res.data.result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(
          err?.data?.message ||
            err?.response ||
            err?.message ||
            err?.response?.data?.message
        );
      }
    };
    fetchDataFromBackend();
  }, []);
  return (
    <LayoutNew>
      <SearchBox />
      <HeaderNew />
      <MainLayout>
        <NavigationTitle title="History" />
        {loading ? (
          <LoadingNew />
        ) : (
            <div className={classes.quizes_container}>
            {histories.map((data,i) => {
              return (
                <div
                  key={i}
                  className={classes.quiz_container}
                >
                  <div className={classes.quiz_card}>
                    <div className={classes.quiz_image}>
                      <img
                        src={data?.categoryImage}
                        alt={data?.categoryName}
                        className={classes.image}
                      />
                    </div>
                  </div>

                  <div className={classes.quiz_data_container}>
                    <div className={classes.quiz_title}>
                      <h3>{data?.categoryName}</h3>
                    </div>
                    <div className={classes.quiz_description}>
                      <p>Score: {data?.score}</p>
                    </div>
                  </div>
                  <div
                    className={classes.icon}
                  >
                    <p>{data.dateTime.substring(0,10)}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </MainLayout>
    </LayoutNew>
  );
};

export default History3;
