import { createSlice } from "@reduxjs/toolkit";

const initialState={
    loginModalBox:false
}

const LoginModalSlice = createSlice({
  name: "loginModal",
  initialState,
  reducers: {
    showLoginModalBox: (state, action) => {
        state.loginModalBox=true;
        return state;
    },
    hideLoginModalBox: (state, action) => {
        state.loginModalBox=false;
        return state;
    },
  },
});

export const { showLoginModalBox, hideLoginModalBox } = LoginModalSlice.actions;
export default LoginModalSlice;
