import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider } from "@mui/material";

export default function AccordianComponent({
  correctAnswer,
  wrongAnswer,
  correctQuestions,
  wrongQuestions,
  answerChoosed,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          backgroundColor: "#c1c1c1",
          width: {
            xs: "80%",
            sm: "80%",
            md: "80%",
            lg: "60%",
            xl: "60%",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ fontSize: "2rem", color: "black" }} />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            sx={{ flexShrink: 0, fontSize: "2rem", fontWeight: "bold" }}
          >
            Correct Answers
          </Typography>
          <Typography
            sx={{ fontSize: "2rem", fontWeight: "bold", marginLeft: "1rem" }}
          >
            {correctAnswer}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {correctQuestions.length > 0 &&
              correctQuestions.map((questions, i) => {
                return (
                  <Box
                    key={i}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                      }}
                    >
                      <Typography sx={{ fontSize: "1.4rem" }}>
                        {i + 1}).
                      </Typography>
                      <Typography sx={{ fontSize: "1.4rem" }}>
                        {questions?.question}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ marginLeft: "2rem", fontSize: "1.4rem" }}
                    >
                      Correct Answer: {questions?.correctAnswer}
                    </Typography>
                    <Divider />
                  </Box>
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{
          backgroundColor: "#c1c1c1",
          width: {
            xs: "80%",
            sm: "80%",
            md: "80%",
            lg: "60%",
            xl: "60%",
          },
          marginTop: "1rem",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ fontSize: "2rem", color: "black" }} />
          }
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography
            sx={{ flexShrink: 0, fontSize: "2rem", fontWeight: "bold" }}
          >
            Wrong Answers
          </Typography>
          <Typography
            sx={{ fontSize: "2rem", fontWeight: "bold", marginLeft: "1rem" }}
          >
            {wrongAnswer}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {wrongQuestions.length > 0 &&
              wrongQuestions.map((questions, i) => {
                return (
                  <Box
                    key={i}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                      }}
                    >
                      <Typography sx={{ fontSize: "1.4rem" }}>
                        {i + 1}).
                      </Typography>
                      <Typography sx={{ fontSize: "1.4rem" }}>
                        {questions?.question}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ marginLeft: "2rem", fontSize: "1.4rem" }}
                    >
                      Correct Answer: {questions?.correctAnswer}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ marginLeft: "2rem", fontSize: "1.4rem" }}
                    >
                      Your Answer: {answerChoosed[i]}
                    </Typography>
                    <Divider />
                  </Box>
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
