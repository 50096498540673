import React, { useEffect, useState } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import NavigationTitle from "../../components/newComponents/NavigationTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetScore } from "../../slices/userSlice";
import { ResetIndex } from "../../slices/indexSlice";
import { ResetTime, ResetTimeForProgress } from "../../slices/timeSlice";
import {
  resetOpponentScore,
  setOpponentImage,
  setOpponentName,
} from "../../slices/opponentSlice";
import { resetQuestions } from "../../slices/questionsSlice";
import {
  resetCorrectAnswer,
  resetWrongAnswer,
} from "../../slices/answersSlice";
import { toast } from "react-toastify";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import classes from "./FindingOpponent2.module.css";
import SearchBox from "../../components/newComponents/SearchBox";

const sampleOpponents = [
  {
    image: "/images/opponent1-girl.png",
    name: "Emily",
  },
  {
    image: "/images/opponent5-girl.jpg",
    name: "Olivia",
  },
  {
    image: "/images/opponent3-boy.jpg",
    name: "Jackson",
  },
  {
    image: "/images/opponent4-boy.jpg",
    name: "Michael",
  },
];

const FindingOpponent2 = () => {
  const { category, categoryId, mode, modePoint } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [opponentState, setOpponentState] = useState(sampleOpponents);
  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);

  const { opponent } = useSelector((state) => state.opponent);
  const { points } = useSelector((state) => state.user.user);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setOpponentState(sampleOpponents);
  //     setIndex(Math.floor(Math.random() * (opponentState.length - 0)) + 0);
  //     dispatch(setOpponentName(opponentState[index]?.name));
  //     dispatch(setOpponentImage(opponentState[index]?.image));
  //     setShow(true);
  //   }, 500);
  // }, []);

  useEffect(() => {
    // Set a timeout and store the timeout ID
    const timeoutId = setTimeout(() => {
      setOpponentState(sampleOpponents);
      setIndex(Math.floor(Math.random() * (opponentState.length - 0)) + 0);
      dispatch(setOpponentName(opponentState[index]?.name));
      dispatch(setOpponentImage(opponentState[index]?.image));
      setShow(true);
    }, 500);

    // Clear the timeout if needed (e.g., if the component unmounts)
    return () => clearTimeout(timeoutId);
  }, []);

  dispatch(setOpponentName(opponentState[index]?.name));
  dispatch(setOpponentImage(opponentState[index]?.image));

  const handleNavigate = (route) => {
    if (points < modePoint) {
      toast.error("You do not have enough points to play!");
      return;
    }
    navigate(route);
    dispatch(resetScore());
    dispatch(ResetIndex());
    dispatch(ResetTime());
    dispatch(ResetTimeForProgress());
    dispatch(resetOpponentScore());
    dispatch(resetQuestions());
    dispatch(resetCorrectAnswer());
    dispatch(resetWrongAnswer());
  };
  return (
    <LayoutNew>
      <HeaderNew />
      <SearchBox />
      <MainLayout>
        <NavigationTitle title="Finding an Opponent" />
        <Box
          my={2}
          sx={{
            backgroundColor: "#e9f1fa",
            width: "80%",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem 2rem",
            }}
          >
            <Box sx={{ borderRadius: "50%", width: "20%" }}>
              <img
                style={{
                  borderRadius: "50%",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "center center",
                }}
                src="/images/Avatar.jpg"
                alt="You"
              />
              <h4 className={classes.text}>You</h4>
              {/* <Typography sx={{ textAlign: "center" }} variant="h6">
              </Typography> */}
            </Box>
            <Box
              mb={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3 className={classes.text_vs}>VS</h3>
              {/* <Typography variant="h5">VS</Typography> */}
            </Box>
            {show ? (
              <Box sx={{ borderRadius: "50%", width: "20%" }}>
                <img
                  loading="lazy"
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    objectPosition: "center center",
                  }}
                  // src={opponentState[index]?.image}
                  src={opponent?.image}
                  alt="random"
                />
                <h4 className={classes.text}>{opponent?.name}</h4>
                {/* <Typography sx={{ textAlign: "center" }} variant="h6">
                  {opponent?.name}
                </Typography> */}
              </Box>
            ) : (
              <Box>
                <Box sx={{ display: "block" }}>
                  <CircularProgress
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </Box>
                <Box sx={{ position: "relative" }}>
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "10%",
                      right: "10%",
                      textAlign: "center",
                      fontSize: "1.5rem",
                      color: "#1F1C3A",
                    }}
                    variant="h6"
                  >
                    Finding ..
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box mt={2}>
            <h3 className={classes.text}>
              {show ? "Challenge Accepted!" : "Finding Opponent..."}
            </h3>
            {/* <Typography sx={{ textAlign: "center" }} variant="h6">
              {show ? "Challenge Accepted!" : "Finding Opponent..."}
            </Typography> */}
          </Box>
          <Box my={2} sx={{ padding: "0 10% 0 10%" }}>
            <Button
              variant="contained"
              disabled={!show}
              // will be dynamic route
              onClick={() =>
                handleNavigate(
                  `/game/${category}/${categoryId}/play/${mode}/${modePoint}`
                )
              }
              sx={{
                // backgroundColor: "#FE9E44",
                fontWeight: "bold",
                // color: "#fff",
                width: "100%",
                fontSize: "1.5rem",
                // margin: "0 10% 0 10%",
              }}
            >
              Play
            </Button>
          </Box>
        </Box>
      </MainLayout>
    </LayoutNew>
  );
};

export default FindingOpponent2;
