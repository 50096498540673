import React, { useEffect, useState } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import TabsNew from "../../components/newComponents/TabsNew";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL, quizURL } from "../../api";
import { toast } from "react-toastify";
import NavigationTitle from "../../components/newComponents/NavigationTitle";
import classes from "./AllQuizes2.module.css";
import SearchBox from "../../components/newComponents/SearchBox";
import LoadingNew from "../../components/newComponents/LoadingNew";

const AllQuizes2 = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${baseURL}${quizURL}`);
        console.log(res);
        setQuizes(res.data.result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(err?.response?.data?.message || err?.message);
      }
    };
    fetchDataFromBackend();
  }, []);

  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
  };

  return (
    <LayoutNew>
      <HeaderNew />
      <SearchBox />
      <MainLayout>
        <TabsNew value={1} />
        <NavigationTitle title="All Quizes" />
        {loading ? (
          <LoadingNew />
        ) : (
          <div className={classes.quizes_container}>
            {quizes.map((data) => {
              return (
                <div
                  key={data?.id}
                  onClick={() =>
                    handleNavigate(
                      `/game/details/${data.categoryName}/${data.categoryId}`
                    )
                  }
                  className={classes.quiz_container}
                >
                  <div className={classes.quiz_card}>
                    <div className={classes.quiz_image}>
                      <img
                        src={data?.categoryImage}
                        alt={data?.categoryName}
                        className={classes.image}
                      />
                    </div>
                  </div>

                  <div className={classes.quiz_data_container}>
                    <div className={classes.quiz_title}>
                      <h3>{data?.categoryName}</h3>
                    </div>
                    <div className={classes.quiz_description}>
                      <p>{data?.description}</p>
                    </div>
                  </div>
                  <div
                    className={classes.icon}
                    onClick={() =>
                      handleNavigate(
                        `/game/details/${data.categoryName}/${data.categoryId}`
                      )
                    }
                  >
                    <i
                      className={`fa-solid fa-arrow-right ${classes.next_icon}`}
                    ></i>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </MainLayout>
    </LayoutNew>
  );
};

export default AllQuizes2;
