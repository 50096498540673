import React, { useEffect, useState } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import TabsNew from "../../components/newComponents/TabsNew";
import Lottie from "lottie-react";
import loginAnimation from "../../staticAnimations/loginAnimation.json";
import classes from "./Signup2.module.css";
import Loader from "../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL, registerURL } from "../../api";
import { setUserCredentials } from "../../slices/userSlice";
import SearchBox from "../../components/newComponents/SearchBox";

const Signup2 = () => {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const { open } = useSelector((state) => state.menu);
  
    const submitHandler = async (e) => {
      e.preventDefault();
      console.log({ username, password });
  
      try {
        if (!username || !password) {
          toast.error("Email or password fields cannot be empty");
          return;
        }
        const data = { username, password };
        setLoading(true);
        const res = await axios.post(`${baseURL}${registerURL}`, data);
        console.log(res.data);
        dispatch(setUserCredentials(res.data));
        setLoading(false);
        navigate("/");
        toast.success("Logged in successfully");
      } catch (err) {
        console.log(err);
        setLoading(false);
        toast.error(
          err?.message ||
            err?.response?.data?.response ||
            err?.error ||
            err?.response
        );
      }
    };
  return (
    <LayoutNew>
      <SearchBox />
    <HeaderNew />
    <MainLayout>
      <TabsNew value={3} label="Sign up" />
      {/* <div className={classes.login_text}>
        <h3 className={classes.text}>Login to play the quiz</h3>
      </div> */}
      <div className={classes.container}>
        <div className={classes.animation_container}>
          <Lottie
            animationData={loginAnimation}
            loop={true}
            autoplay={true}
            className={classes.animation}
          />
        </div>
        <div className={classes.login_container}>
          <form className={classes.form_signup} onSubmit={submitHandler}>
            <div className={classes.input_group}>
              <input
                type="number"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Number"
              />
              <i className="fa-solid fa-user"></i>
            </div>
            <div className={classes.input_group}>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <i className="fa-solid fa-lock"></i>
            </div>
            <button className={classes.form_btn} type="submit">
              Sign up
            </button>
            {loading && <Loader />}
            <div
              className={classes.form_link}
              onClick={() => navigate("/login")}
            >
              <Link to="/login"> Already an user , Login</Link>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  </LayoutNew>
  )
}

export default Signup2