import React, { useEffect, useState } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import TabsNew from "../../components/newComponents/TabsNew";
import NavigationTitle from "../../components/newComponents/NavigationTitle";
import classes from "./Leaderboard2.module.css";
import leaderboardAnimation from "../../staticAnimations/leaderboardAnimation.json";
import award1Animation from "../../staticAnimations/award1.json";
import award2Animation from "../../staticAnimations/award2.json";
import award3Animation from "../../staticAnimations/award3.json";
import axios from "axios";
import { baseURL, leaderBoardURL } from "../../api";
import Lottie from "lottie-react";
import { toast } from "react-toastify";
import SearchBox from "../../components/newComponents/SearchBox";
import LoadingNew from "../../components/newComponents/LoadingNew";

const Leaderboard2 = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${baseURL}${leaderBoardURL}`);
        setData(res.data.result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(
          err?.data?.message ||
            err?.message ||
            err?.response ||
            err?.response?.message ||
            err?.response?.data?.message
        );
      }
    };
    fetchDataFromBackend();
  }, []);
  return (
    <LayoutNew>
      <HeaderNew />
      <SearchBox />
      <MainLayout>
        <TabsNew value={2} />
        <NavigationTitle title="Leaderboard" />
        {loading ? (
        <LoadingNew />
        ) : (
          <div className={classes.leaderboard_container}>
            <div className={classes.leaderAnimation_container}>
              <Lottie
                animationData={leaderboardAnimation}
                loop={true}
                autoplay={true}
                className={classes.leaderboardAnimation}
              />
            </div>
            <div className={classes.leaderboard_positions_container}>
              <div className={classes.leaderboard_top3_positions}>
                <div className={classes.position2}>
                  <div className={classes.position2_animation_container}>
                    <Lottie
                      animationData={award2Animation}
                      loop={true}
                      autoplay={true}
                      className={classes.award2Animation}
                    />
                  </div>
                  <div className={classes.player_info}>
                    <h3 className={classes.player_text}>
                      <span>User: </span>
                      {data[1]?.ani}
                    </h3>
                    <h3 className={classes.player_text}>
                      <span>Points: </span>
                      {data[1]?.points}
                    </h3>
                  </div>
                </div>
                <div className={classes.position1}>
                  <div className={classes.position1_animation_container}>
                    <Lottie
                      animationData={award1Animation}
                      loop={true}
                      autoplay={true}
                      className={classes.award1Animation}
                    />
                  </div>
                  <div className={classes.player_info}>
                    <h3 className={classes.player_text}>
                      <span>User: </span>
                      {data[0]?.ani}
                    </h3>
                    <h3 className={classes.player_text}>
                      <span>Points: </span>
                      {data[0]?.points}
                    </h3>
                  </div>
                </div>
                <div className={classes.position3}>
                  <div className={classes.position3_animation_container}>
                    <Lottie
                      animationData={award3Animation}
                      loop={true}
                      autoplay={true}
                      className={classes.award3Animation}
                    />
                  </div>
                  <div className={classes.player_info}>
                    <h3 className={classes.player_text}>
                      <span>User: </span>
                      {data[2]?.ani}
                    </h3>
                    <h3 className={classes.player_text}>
                      <span>Points: </span>
                      {data[2]?.points}
                    </h3>
                  </div>
                </div>
              </div>

              <div className={classes.leaderboard_table}>
                {data?.slice(3, 10)?.map((dataItem, i) => {
                  return (
                    <div className={classes.leaderboard_data_container}>
                      <div className={classes.leaderboard_data}>
                        <div className={classes.circle_container}>
                          <div className={classes.circle}>
                            <h3>{i + 4}</h3>
                          </div>
                        </div>
                        <div className={classes.user}>
                          <h3>User: {dataItem?.ani}</h3>
                        </div>
                        <div className={classes.points}>
                          <h3>Points: {dataItem?.points}</h3>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    </LayoutNew>
  );
};

export default Leaderboard2;
