import React, { useEffect, useState } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import TabsNew from "../../components/newComponents/TabsNew";
import Lottie from "lottie-react";
import loginAnimation from "../../staticAnimations/loginAnimation.json";
import classes from "./Login2.module.css";
import { Box, TextField, Button, Typography } from "@mui/material";
import Loader from "../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL, loginURL } from "../../api";
import { setUserCredentials } from "../../slices/userSlice";
import SearchBox from "../../components/newComponents/SearchBox";

const Login2 = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { open } = useSelector((state) => state.menu);

  useEffect(() => {
    if (user) {
      navigate("/account");
    }
  }, [navigate]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (!username || !password) {
        toast.error("Email or password fields cannot be empty");
        return;
      }
      const data = { username, password };
      setLoading(true);
      const res = await axios.post(`${baseURL}${loginURL}`, data);
      console.log(res);
      dispatch(setUserCredentials(res.data));
      setLoading(false);
      navigate("/");
      toast.success("Logged in successfully");
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(
        err?.message ||
          err?.response?.data?.response ||
          err?.error ||
          err?.response
      );
    }
  };
  return (
    <LayoutNew>
      <SearchBox />
      <HeaderNew />
      <MainLayout>
        <TabsNew value={3} label="Login" />
        {/* <div className={classes.login_text}>
          <h3 className={classes.text}>Login to play the quiz</h3>
        </div> */}
        <div className={classes.container}>
          <div className={classes.animation_container}>
            <Lottie
              animationData={loginAnimation}
              loop={true}
              autoplay={true}
              className={classes.animation}
            />
          </div>
          <div className={classes.login_container}>
            <form className={classes.form_signup} onSubmit={submitHandler}>
              <div className={classes.input_group}>
                <input
                  type="number"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Number"
                />
                <i className="fa-solid fa-user"></i>
              </div>
              <div className={classes.input_group}>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                <i className="fa-solid fa-lock"></i>
              </div>
              <button className={classes.form_btn} type="submit">
                Login
              </button>
              {loading && <Loader />}
              <div
                className={classes.form_link}
                onClick={() => navigate("/signup")}
              >
                <Link to="/signup"> Sign up , if you are not a User</Link>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </LayoutNew>
  );
};

export default Login2;
