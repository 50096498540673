import React from "react";
import classes from "./LayoutNew.module.css";

const LayoutNew = ({children}) => {
  return (
    <div className={classes.container}>
      <div className={classes.sub_container}>{children}</div>
    </div>
  );
};

export default LayoutNew;
