import React, { useCallback } from "react";
import classes from "./PopularQuizNew.module.css";
import { useNavigate } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";

const PopularQuizNew = ({ popularQuizes }) => {
  const navigate = useNavigate();

  const handleNavigate = (route) => {
    navigate(route);
  };

  //Owl Carousel Settings
  const options = {
    loop: true,
    items: 3,
    margin: 0,
    margin: 20,
    center: true, // Center the active item
    // autoWidth: true, // Adjust item width automatically
    nav: false,
    autoplay: true,
    dots: true,
    autoplayTimeout: 3000, // Autoplay interval in milliseconds
    autoplayHoverPause: true,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <>
      <div className={classes.popular_quiz_heading}>
        <h1>Popular Quiz</h1>
      </div>

      <div className={classes.popular_quiz_container}>
        <OwlCarousel className="owl-theme" {...options}>
          {popularQuizes.map((dataItem, i) => {
            return (
              <div
                onClick={() =>
                  handleNavigate(
                    `/game/details/${dataItem.categoryName}/${dataItem.categoryId}`
                  )
                }
                className={classes.quiz_card}
                key={i}
              >
                <div className={classes.quiz_image}>
                  <img
                    className={classes.image}
                    alt={dataItem?.categoryName}
                    src={dataItem.categoryImage}
                  />
                </div>
                <h3 className={classes.text}>{dataItem.categoryName}</h3>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
    </>
  );
};

export default PopularQuizNew;
