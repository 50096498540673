import React, { useEffect } from "react";
import classes from "./Account2.module.css";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import TabsNew from "../../components/newComponents/TabsNew";
import NavigationTitle from "../../components/newComponents/NavigationTitle";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeUserCredentials } from "../../slices/userSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SearchBox from "../../components/newComponents/SearchBox";

const Account2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  // const token = user?.response;
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const logoutHandler = () => {
    dispatch(removeUserCredentials());
  };
  return (
    <LayoutNew>
      <HeaderNew />
      <SearchBox />
      <MainLayout>
        <TabsNew value={3} />
        {/* <NavigationTitle title="Account" /> */}
        <div className={classes.container}>
          <div className={classes.sub_container}>
            <div className={classes.user_avatar_and_name}>
              <Avatar className={classes.avatar} />
              <div className={classes.name}>
                <h3 className={classes.text}>{user?.username}</h3>
              </div>
            </div>
            <div className={classes.subscriber_info}>
              <span className={classes.coin}></span>
              <span className={classes.points}>{user?.points}</span>
            </div>
          </div>

          <div className={classes.main_container}>
            <div className={classes.history_container} onClick={() => navigate("/history")}>
              <div className={classes.history_icon_and_text}>
                <SportsEsportsIcon
                  sx={{ color: "rgb(226 232 240)", fontSize: "2rem" }}
                />
                <div className={classes.history_text_container}>
                  <h3>History</h3>
                </div>
              </div>
              <div className={classes.arrow_container}>
                <ArrowForwardIosIcon
                  sx={{ fontSize: "2rem", color: "white" }}
                />
              </div>
            </div>
            <div className={classes.logout_container} onClick={logoutHandler}>
              <div className={classes.history_icon_and_text}>
                <ExitToAppIcon sx={{ color: "#1F1C3A", fontSize: "2rem" }} />
                <div className={classes.logout_text_container}>
                  <h3>Logout</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </LayoutNew>
  );
};

export default Account2;
