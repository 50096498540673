import React from "react";
import classes from "./FeaturedQuizNew.module.css";
import { useNavigate } from "react-router-dom";

const FeaturedQuizNew = ({ quizes }) => {
  const navigate = useNavigate();

  const handleNavigate = (route) => {
    navigate(route);
  };
  return (
    <>
      <div className={classes.featured_quiz_heading}>
        <h1>Featured Quiz</h1>
      </div>

      <div className={classes.featured_quiz_container}>
        <div className={classes.featured_quiz_subcontainer}>
          {quizes.map((dataItem, i) => {
            return (
              <div
                onClick={() =>
                  handleNavigate(
                    `/game/details/${dataItem.categoryName}/${dataItem.categoryId}`
                  )
                }
                className={classes.quiz_card}
                key={i}
              >
                <div className={classes.quiz_image}>
                  <img
                    className={classes.image}
                    alt={dataItem?.categoryName}
                    src={dataItem.categoryImage}
                  />
                </div>
                <h3 className={classes.text}>{dataItem.categoryName}</h3>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FeaturedQuizNew;
